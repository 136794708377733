import React, { memo, useMemo } from "react"

import { useRoutes } from "../../../hooks/useRoutes"
import { useWishlistContext } from "../../../hooks/useWishlist"

export const withAccountLogin = Component =>
  memo(({ name = "AccountLogin", forgot, page, register }: any) => {
    const { linkResolver } = useRoutes()
    const { added } = useWishlistContext()

    const links = useMemo(
      () => ({
        forgot: linkResolver(forgot, `forgot`),
        register: linkResolver(register, `register`),
      }),
      [forgot, linkResolver, register]
    )
    const wishlist = added ? page?.additionalWishlist?.replace(`{title}`, `“${added?.product?.title}”`) : null

    Component.displayName = name
    return useMemo(() => <Component links={links} page={page} wishlist={wishlist} />, [links, page, wishlist])
  })
