import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountLogin } from "./withAccountLogin"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { AccountLoginForm } from "./Form/AccountLoginForm"
import { StyledContainer } from "../../Styled/Container"
import { StyledColumn } from "../../Styled/Column"
import { StyledLink } from "../../Styled/Button"
import { StyledRow } from "../../Styled/Row"
import { Notification } from "../../Styled/Text"
import { Page, Text, Title } from "./styledAccountLogin"

export const AccountLogin = withAccountLogin(
  ({ links, page, wishlist }): JSX.Element => (
    <Page>
      <Breadcrumbs background={`orange-pastel`} colour={`dark`} page={page} />
      <StyledContainer width={`md`}>
        <Title as={`h1`} align={`center`} withSpacing>
          {page?.title}
        </Title>
        {wishlist && (
          <Notification align={`center`} colour={`orange-dark`} withSpacing>
            {wishlist}
          </Notification>
        )}
        <AccountLoginForm page={page} />
        <StyledRow gutter={`xxs`} items={`center`} justify={`center`} withSpacing={`xs`}>
          <StyledColumn gutter={`xxs`} width={`no-shrink`}>
            <Text as={`p`} align={`center`} colour={`grey-darker`}>
              {page?.additionalRegister}
            </Text>
          </StyledColumn>
          <StyledColumn gutter={`xxs`} width={`no-shrink`}>
            <StyledLink as={GatsbyLink} colour={`orange-dark`} size={`large`} title={links?.register?.title} to={links?.register?.url}>
              {links?.register?.title}
            </StyledLink>
          </StyledColumn>
        </StyledRow>
        <StyledLink as={GatsbyLink} colour={`grey-darker`} size={`large`} title={links?.forgot?.title} to={links?.forgot?.url}>
          {page?.additionalForgot}
        </StyledLink>
      </StyledContainer>
    </Page>
  )
)
