import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../../hoc/withoutAuthentication"
import { AccountLogin as Page } from "../../components/Account/Login/AccountLogin"

export const query = graphql`
  query {
    page: sanityPageAccountLogin {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalEmail
      additionalPassword
      additionalForgot
      additionalRegister
      additionalSubmit
      additionalWishlist
    }
    forgot: sanityPageAccountForgot {
      title
    }
    register: sanityPageAccountRegister {
      title
    }
    template: sanityTemplateAccount {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = withoutAuthentication(({ data, ...props }) => <Page {...props} {...data} />)

export default Component
