import React, { memo, useCallback, useMemo } from "react"

import { useCustomerLogin } from "../../../../hooks/useCustomer"

export const withAccountLoginForm = Component =>
  memo(({ name = "AccountLoginForm", page }: any) => {
    const { loginCustomer, data, setData, loading, errors } = useCustomerLogin()

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()
        await loginCustomer({
          ...data,
        })
      },
      [data, loginCustomer]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    Component.displayName = name
    return useMemo(
      () => <Component data={data} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} locales={page} />,
      [data, errors, handleChange, handleSubmit, loading, page]
    )
  })
